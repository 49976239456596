import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, bgcolor}) => {
  
  const myFunction = () => {
    var x = document.getElementById('menu')
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  return (
  <header
    style={ (!bgcolor) ? {
      marginBottom: `1.45rem`,
      // background:  `rgb(255,219,0)`,
      zIndex: 50,
      background: `linear-gradient(90deg, rgba(255,219,0,1) 0%, rgba(255,159,0,1) 100%)`
    } : {
      marginBottom: `1.45rem`,
      zIndex: 50,
      background: bgcolor,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >          
          <h1>MKL</h1>
          <h6>Public Relations</h6>
          
        </Link>
      </h1>
    </div>
    <div id="menu" className="topnav">
      <ul id="nav">
        <li><Link to="/about">about MKL</Link></li>
        <li><Link to="/capabilities">our capabilities</Link></li>
        <li><Link to="/industries">client industries</Link></li>
        <li><Link to="/media">media placement</Link></li>
      </ul>
      <a href="#" className="icon" onClick={ myFunction.bind(this) }>
        <i className="fa fa-bars"></i>
      </a>
      <div id="public-relations"></div>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
